import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
// import Spinner from '../../SlidesSpinner/Spinner';

const Container = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ overflowX: 'scroll', width: '500px' }}>
      {props.children}
    </div>
  )
})

 const Images = ({ images, style }) => {
  const s = require('./images.module.scss');
  const lazyRoot = React.useRef(null)
  return (
    <div className={s.ImageContainer} style={{ backgroundImage: `url(${images.large})` }}>
      <Image
        className={s.Image}
        src={images.large}
        alt="slider"
        style={{ opacity: 0 }}
        lazyRoot={lazyRoot}
        width={360} 
        height={310}
      />
    </div>
  );
}

Images.propTypes = {
  image: PropTypes.string,
  style: PropTypes.object,
};

export default Images;